<template>
  <VeeForm
    v-slot="{ errors, values }"
    :class="`form form--${type}`"
    @submit="submit"
  >
    <div
      v-for="(
        {
          name,
          class: className,
          placeholder,
          type: inputType,
          as,
          rules,
          label,
          icon,
          header,
          options,
          withPopup
        },
        i
      ) in formFields"
      :key="`${name}-${i}`"
      class="field"
      :class="[{ [`field--${inputType}`]: inputType }, className]"
    >
      <span v-if="header" class="header" v-html="nbsp(header)" />

      <BaseButton
        v-if="inputType === 'submit'"
        class="submit-button"
        type="submit"
        color="tertiary"
        :loading="isLoading"
        :text="buttonText(label)"
        :icon="icon"
      />
      <Component
        :is="withPopup ? 'span' : 'label'"
        v-else
        class="label"
        :class="{
          'label--with-checkbox': isCheckbox(inputType),
          'label--error': errors[name],
          'label--with-value': !!values[name]
        }"
      >
        <BaseTelInput
          v-if="isTelField(inputType)"
          :required="rules?.includes('required')"
          :name="name"
          :placeholder="placeholder"
          class="input"
        />
        <span v-else-if="isDateField(inputType)" class="input-wrapper">
          <BaseDateInput :rules="rules" :name="name" class="input" />

          <PlusIcon class="select-icon" />
        </span>
        <span v-else-if="isSelectField(inputType)" class="input-wrapper">
          <Field
            class="input"
            :name="name"
            :type="inputType"
            as="select"
            :placeholder="placeholder"
            :rules="rules"
          >
            <option
              v-for="option in options"
              :key="option.value"
              :value="option.value"
              class="option"
            >
              {{ option.label }}
            </option>
          </Field>

          <PlusIcon class="select-icon" />
        </span>
        <Component
          :is="isCheckbox(inputType) ? 'label' : 'span'"
          v-else
          class="input-wrapper"
        >
          <Field
            class="input"
            :class="{
              'visually-hidden': isCheckbox(inputType)
            }"
            :name="name"
            :type="inputType"
            :as="as"
            :value="isCheckbox(inputType) ? true : ''"
            :placeholder="placeholder"
            :rules="rules"
          />
          <span v-if="isCheckbox(inputType)" class="checkbox">
            <CheckIcon class="checkbox-icon" />
          </span>
        </Component>

        <Component
          :is="isCheckbox(inputType) ? 'button' : 'span'"
          v-if="label"
          class="label-text"
          :class="{ 'label-text--inside': !isCheckbox(inputType) }"
          v-bind="isCheckbox(inputType) ? { inputType: 'button' } : {}"
          v-on="
            isCheckbox(inputType) ? { click: () => (isPopupActive = true) } : {}
          "
        >
          <span
            v-html="
              nbsp(
                rules?.includes('required')
                  ? label
                  : `${label} (${t('form.optional')})`
              )
            "
          />
        </Component>

        <Transition name="fade" mode="out-in">
          <span
            v-if="
              (!inputType || isTelField(inputType)) &&
              !errors[name] &&
              values[name]
            "
            class="valid"
          >
            <CheckIcon class="valid-icon" />
          </span>
        </Transition>

        <Transition name="fade">
          <Tooltip v-if="errors[name]" class="error-tooltip">
            <WarningCircleIcon class="error-icon" />
            <span class="error" v-text="errors[name]" />
          </Tooltip>
        </Transition>
      </Component>
    </div>

    <div class="error-wrap" v-text="formError" />
    <PrivacyPolicyPopup
      :active="isPopupActive"
      @close="isPopupActive = false"
    />
  </VeeForm>
</template>

<script lang="ts" setup>
import { required, email, max } from '@vee-validate/rules'
import { differenceInMilliseconds } from 'date-fns'
import { Form as VeeForm, Field, configure, defineRule } from 'vee-validate'

import CheckIcon from '@/assets/icons/check-form.svg?component'
import PlusIcon from '@/assets/icons/plus.svg?component'
import WarningCircleIcon from '@/assets/icons/warning-circle.svg?component'

const { t } = useI18n()

configure({
  validateOnInput: true
})

defineRule(
  'required',
  (value: string): boolean | string => required(value) || t('form.required')
)

defineRule(
  'agreement-required',
  (value: string): boolean | string =>
    required(value) || t('form.agreement-required')
)

defineRule(
  'max-length-350',
  (value: string): boolean | string =>
    max(value, [350]) || t('form.incorrect-message')
)

defineRule(
  'email',
  (value: string): boolean | string => email(value) || t('form.incorrect-email')
)

defineRule(
  'future-date',
  (value: Date): boolean | string =>
    differenceInMilliseconds(value, new Date()) > 0 || t('form.future-date')
)

type InputType = {
  label?: string
  name: string
  rules?: string
  placeholder?: string
  type?: string
  as?: string
  class?: string
  icon?: string
  header?: string
  options?: { label: string | number; value: string | number }[]
  withPopup?: boolean
}

const props = withDefaults(
  defineProps<{
    formFields: InputType[]
    formError?: string
    success?: boolean
    isLoading: boolean
    type?: 'primary' | 'secondary'
  }>(),
  {
    formError: '',
    success: false,
    isLoading: false,
    type: 'primary'
  }
)

const emit = defineEmits(['submit'])

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericObject = Record<string, any>
const submit = (formData: GenericObject, formActions?: GenericObject) => {
  emit('submit', formData, formActions)
}
const isTelField = (type?: string) => type === 'tel'
const isSelectField = (type?: string) => type === 'select'
const isDateField = (type?: string) => type === 'date'
const isCheckbox = (type?: string) => type === 'checkbox'
const buttonText = (label?: string) =>
  props.isLoading ? t('form.submitting') : label || t('form.submit')

const isPopupActive = ref(false)
</script>

<style lang="scss" scoped>
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: rem(29px);
}

.field {
  position: relative;
  grid-column: 1 / -1;

  &--half {
    @include media-up(md) {
      grid-column: 1 / 2;
    }

    &:nth-of-type(2n) {
      @include media-up(md) {
        grid-column: 2 / -1;
      }
    }
  }
}

.header {
  font-size: rem(16px);
  font-weight: 400;
  line-height: 2.5;
  color: $dark-brown;
  letter-spacing: -0.025em;

  @include media-up(xl) {
    font-size: rem(22px);
  }
}

.checkbox-icon {
  @include size(10px);
  opacity: 0;
  transition: opacity $transition;

  :deep(path) {
    stroke: $dark-brown !important;
  }
}

.checkbox {
  @include size(20px);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: 1px solid $dark-brown;
  border-radius: $border-radius;
  box-shadow: 0 3px 3px 0 #00000029;
  transition: background-color $transition;

  @include media-up(lg) {
    @include size(17px);
  }

  @include media-up(xl) {
    @include size(24px);
    border-radius: $border-radius-xl;
  }

  .form--secondary & {
    background: $form-background;
    border-color: $beige;
    box-shadow: $form-shadow;
  }

  .contrast-mode & {
    border-color: $contrast-yellow;
  }

  .input:checked + & {
    background: $beige;

    .checkbox-icon {
      opacity: 1;
    }
  }
}

.valid {
  @include center-self(vertically);
  @include size(20px);
  right: rem(21px);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  background: $dark-brown;
  border-radius: $border-radius;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.12);

  @include media-up(lg) {
    @include size(17px);
  }

  .form--secondary & {
    background: $beige;
    box-shadow: $form-shadow;
  }

  .contrast-mode & {
    background: $contrast-yellow;
  }
}

.valid-icon {
  @include size(10px);

  :deep(path) {
    stroke: $beige !important;

    .form--secondary & {
      stroke: $dark-brown !important;
    }

    .contrast-mode & {
      stroke: $contrast-black !important;
    }
  }
}

.select-icon {
  @include center-self(vertically);
  @include size(rem(10px));
  right: rem(22px);
  pointer-events: none;
}

.input-wrapper {
  position: relative;
  display: flex;
}

.input {
  width: 100%;
  padding: rem(24px) rem(26px) rem(10px);
  font-family: $font;
  line-height: rem(24px);
  color: $dark-brown;
  border: 1px solid $dark-brown;
  border-radius: $border-radius;

  @include media-up(md) {
    padding: rem(20px) rem(26px) rem(10px);
  }

  @include media-up(lg) {
    padding: rem(17px) rem(26px) rem(7px);
  }

  @include media-up(xl) {
    padding: rem(40px) rem(42px) rem(20px);
    font-size: rem(22px);
    border-radius: $border-radius-xl;
  }

  .form--secondary & {
    color: $beige;
    background: $form-background;
    border-color: $beige;
    box-shadow: $form-shadow;
  }

  &::placeholder {
    color: currentColor;
  }

  .contrast-mode & {
    color: $contrast-yellow;
    border-color: $contrast-yellow;

    &::placeholder {
      color: $contrast-yellow;
    }
  }
}

.option {
  color: $dark-brown;
  background-color: $beige;
}

.label {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: rem(6px);
  margin-bottom: rem(15px);

  @include media-up(md) {
    margin-bottom: rem(19px);
  }

  &-text {
    order: -1;
    font-weight: 500;

    &--inside {
      @include font-size(
        (
          xs: 16px,
          md: 15px,
          lg: 14px,
          xl: 18px
        ),
        1.2
      );
      position: absolute;
      top: rem(21px);
      left: rem(27px);
      font-weight: 500;
      transition: transform $transition;
      transform-origin: top left;

      @include media-up(md) {
        top: rem(19px);
      }

      @include media-up(lg) {
        top: rem(17px);
        letter-spacing: 0.025em;
      }

      @include media-up(xl) {
        top: rem(33px);
        left: rem(43px);
      }

      .label--with-value & {
        transform: scale(0.6) translateY(rem(-10px));

        @include media-up(xl) {
          transform: scale(0.6) translateY(rem(-20px));
        }
      }
    }

    :deep([data-tag='p']) {
      display: initial;
    }
  }

  &--error {
    /* stylelint-disable-next-line no-descending-specificity */
    .checkbox,
    .input {
      border-color: $dark-brown;

      .form--secondary & {
        border-color: $beige;
      }

      .contrast-mode & {
        border-color: $yellow;
      }
    }

    .input-wrapper::after {
      opacity: 1;
    }
  }

  &--with-checkbox {
    flex-direction: row;
    gap: rem(12px);

    .label-text {
      order: unset;
      font-size: rem(13px);
      font-weight: 400;
      line-height: 1.2;
      text-align: left;
      letter-spacing: -0.025em;

      @include media-up(xl) {
        font-size: rem(22px);
      }

      .contrast-mode & {
        color: $contrast-black;
      }
    }

    .input-wrapper {
      &::after {
        display: none;
      }
    }
  }
}

input {
  &:focus {
    outline: none;
  }

  &:-internal-autofill-selected {
    background-color: transparent;
    -webkit-text-fill-color: $dark-brown;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: $dark-brown;
  }
}

textarea.input {
  height: rem(188px);
  resize: none;

  @include media-up(xl) {
    border-radius: $border-radius;
  }

  &:focus {
    outline: none;
  }
}

.error-wrap {
  position: relative;
  display: flex;
  grid-column: 1 / -1;
  margin-top: rem(32px);
}

.submit-button {
  height: rem(60px);
  padding: 0;
  color: $dark-brown;
  background: $beige;
  border-radius: $border-radius;

  @include media-up(md) {
    height: rem(56px);
  }

  @include media-up(lg) {
    height: rem(50px);
  }

  @include media-up(xl) {
    height: rem(85px);
    border-radius: $border-radius-xl;
  }

  :deep(.button) {
    --button-text-color: $dark-brown;
    --button-bg-color: $beige;
    --button-bg-hover-color: $beige;
    --button-text-hover-color: $dark-brown;
    height: inherit;
    box-shadow: none;
  }

  .contrast-mode & {
    color: $contrast-yellow;
    background: $contrast-black;
  }
}

.error-tooltip {
  position: absolute;
  top: 60%;
  left: rem(-24px);
  max-width: 100vw;
  text-align: left;

  @include media-up(md) {
    top: 50%;
    left: 50%;
  }

  .field--checkbox & {
    top: rem(20px);
    left: rem(-23px);
  }
}

.error {
  margin-left: rem(11px);
  color: $dark-brown;
}
</style>
