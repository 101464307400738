<template>
  <div
    @mouseenter="animationState = 'enter'"
    @mouseleave="animationState = 'leave'"
  >
    <component
      :is="baseLink ? BaseLinkComponent : tag"
      v-bind="{
        ...$attrs,
        ...(baseLink && { link: baseLink, placeholder }),
        ...(to && { to })
      }"
      :disabled="isDisabled"
      :class="classes"
      class="button t-button"
      :data-label="label"
    >
      <span class="inner">
        <slot name="loader" v-bind="{ loading }">
          <LoaderIcon v-if="loading" class="loader-icon" />
        </slot>
        <span v-if="icon" class="content">
          <BaseIcon :icon="icon" class="icon" />
          <slot />
          <span class="text" v-html="label" />
        </span>
        <slot v-else>
          <span class="text" v-html="label" />
        </slot>
      </span>
    </component>
  </div>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'

import { NuxtLink } from '#components'
import LoaderIcon from '@/assets/icons/loader.svg?component'
import type { PublicLink } from '@/service/__generated-api'

const BaseLinkComponent = resolveComponent('BaseLink')

const props = withDefaults(
  defineProps<{
    color?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
    tag?: 'button' | 'span' | 'a' | typeof NuxtLink
    to?: RouteLocationRaw
    text?: string
    icon?: string
    baseLink?: PublicLink | null
    loading?: boolean
    disabled?: boolean
    placeholder?: boolean
    contrastModeColor?: 'black' | 'yellow'
  }>(),
  {
    color: 'primary',
    tag: 'button',
    to: undefined,
    text: '',
    icon: undefined,
    baseLink: undefined,
    disabled: false,
    loading: false,
    placeholder: false,
    contrastModeColor: 'black'
  }
)

const isDisabled = computed(() => (props.loading ? true : props.disabled))
const classes = computed(() => [
  {
    'button--disabled': isDisabled.value,
    'button--loading': props.loading
  },
  `button--${props.color}`,
  `button--${animationState.value}`,
  `button--contrast-${props.contrastModeColor}`
])

const animationState = ref<'enter' | 'leave' | 'default'>('default')

const label = computed(() => props.text || props.baseLink?.label)
</script>

<style lang="scss" scoped>
$button-text-color: var(--button-text-color, #{$pure-white});
$button-bg-color: var(--button-bg-color, #{$navy-blue-500});
$button-text-hover-color: var(--button-text-hover-color, #{$pure-white});
$button-bg-hover-color: var(--button-bg-hover-color, #{$navy-blue-400});

@mixin btn-style(
  $bg-color,
  $bg-hover,
  $text-color: $pure-white,
  $text-hover-color: $pure-white
) {
  --button-bg-color: #{$bg-color};
  --button-bg-hover-color: #{$bg-hover};
  --button-text-color: #{$text-color};
  --button-text-hover-color: #{$text-hover-color};
}

@mixin btn-empty-style($text-color, $text-hover-color) {
  --button-bg-color: transparent;
  --button-bg-hover-color: transparent;
  --button-text-color: #{$text-color};
  --button-text-hover-color: #{$text-hover-color};
}

.loader-icon {
  --loader-color: #{$primary-blue-500};
  margin-right: rem(8px);
  opacity: 0;
  transition: opacity $transition;
  animation: spinner 1s infinite linear;
}

.button {
  --icon-color: #{$button-text-color};
  @include center-content;
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: rem(37px);
  color: $button-text-color;
  cursor: pointer;
  background-color: $button-bg-color;
  border-radius: rem(1px);
  box-shadow: $base-shadow;
  transition:
    color $transition-slow,
    background-color $transition-slow,
    transform $transition-slow;

  @include media-up(lg) {
    padding: rem(16px);
  }

  &:hover,
  &:focus {
    --button-text-color: #{$button-text-hover-color};
    --button-bg-color: #{$button-bg-hover-color};
  }

  &.button--disabled {
    --button-bg-color: #{$navy-blue-50};
    --button-bg-hover-color: #{$navy-blue-50};
    --button-text-color: #{$pure-white};
    --button-text-hover-color: #{$pure-white};
    pointer-events: none;
    user-select: none;
  }

  &[class*='-empty'] {
    padding: 0;
    border-radius: 0;

    &.button--disabled {
      --button-bg-color: transparent;
      --button-bg-hover-color: transparent;
      --button-text-color: #{$navy-blue-50};
      --button-text-hover-color: #{$navy-blue-50};
    }
  }

  &.button--enter {
    transform: scale(1.1);
  }

  &--primary {
    @include btn-style($orange, $orange);

    &.button--enter {
      @include btn-style($beige, $beige, $navy-blue);
    }
  }

  &--secondary {
    @include btn-style($blue, $blue);

    &.button--enter {
      @include btn-style($dark-brown, $blue);
    }
  }

  &--tertiary {
    @include btn-style($beige, $beige, $navy-blue, $navy-blue);
  }

  &--quaternary {
    @include btn-style($dark-brown, $dark-brown);
  }

  &--loading {
    .loader-icon {
      opacity: 1;
    }
  }

  .contrast-mode &--contrast-black {
    @include btn-style(
      $contrast-black,
      $contrast-black,
      $contrast-yellow,
      $contrast-yellow
    );
  }

  .contrast-mode &--contrast-yellow {
    @include btn-style(
      $contrast-yellow,
      $contrast-yellow,
      $contrast-black,
      $contrast-black
    );
  }
}

.content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.icon {
  width: rem(16px);
  height: rem(16px);
  margin-right: rem(8px);
}

@keyframes spinner {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

.inner {
  position: relative;
  z-index: 2;
}
</style>
