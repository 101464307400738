import { default as _91slug_93msfiAekumQMeta } from "/app/pages/[slug].vue?macro=true";
import { default as accessibility_45declaration1KnSfdkJf3Meta } from "/app/pages/accessibility-declaration.vue?macro=true";
import { default as cookie_45settingspFEbIVlXkZMeta } from "/app/pages/cookie-settings.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91slug_93h6ecm9GkHAMeta } from "/app/pages/news/[slug].vue?macro=true";
import { default as newslJeNrHnHA0Meta } from "/app/pages/preview/news.vue?macro=true";
import { default as simple_45page1iE2CAJyY0Meta } from "/app/pages/preview/simple-page.vue?macro=true";
import { default as component_45stubTOq3NWgWLHMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubTOq3NWgWLH } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91slug_93msfiAekumQMeta?.name ?? "slug___pl",
    path: _91slug_93msfiAekumQMeta?.path ?? "/:slug()",
    meta: _91slug_93msfiAekumQMeta || {},
    alias: _91slug_93msfiAekumQMeta?.alias || [],
    redirect: _91slug_93msfiAekumQMeta?.redirect,
    component: () => import("/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93msfiAekumQMeta?.name ?? "slug___en",
    path: _91slug_93msfiAekumQMeta?.path ?? "/en/:slug()",
    meta: _91slug_93msfiAekumQMeta || {},
    alias: _91slug_93msfiAekumQMeta?.alias || [],
    redirect: _91slug_93msfiAekumQMeta?.redirect,
    component: () => import("/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: accessibility_45declaration1KnSfdkJf3Meta?.name ?? "accessibility-declaration___pl",
    path: accessibility_45declaration1KnSfdkJf3Meta?.path ?? "/deklaracja-dostepnosci",
    meta: accessibility_45declaration1KnSfdkJf3Meta || {},
    alias: accessibility_45declaration1KnSfdkJf3Meta?.alias || [],
    redirect: accessibility_45declaration1KnSfdkJf3Meta?.redirect,
    component: () => import("/app/pages/accessibility-declaration.vue").then(m => m.default || m)
  },
  {
    name: accessibility_45declaration1KnSfdkJf3Meta?.name ?? "accessibility-declaration___en",
    path: accessibility_45declaration1KnSfdkJf3Meta?.path ?? "/en/accessibility-declaration",
    meta: accessibility_45declaration1KnSfdkJf3Meta || {},
    alias: accessibility_45declaration1KnSfdkJf3Meta?.alias || [],
    redirect: accessibility_45declaration1KnSfdkJf3Meta?.redirect,
    component: () => import("/app/pages/accessibility-declaration.vue").then(m => m.default || m)
  },
  {
    name: cookie_45settingspFEbIVlXkZMeta?.name ?? "cookie-settings___pl",
    path: cookie_45settingspFEbIVlXkZMeta?.path ?? "/ustawienia-cookies",
    meta: cookie_45settingspFEbIVlXkZMeta || {},
    alias: cookie_45settingspFEbIVlXkZMeta?.alias || [],
    redirect: cookie_45settingspFEbIVlXkZMeta?.redirect,
    component: () => import("/app/pages/cookie-settings.vue").then(m => m.default || m)
  },
  {
    name: cookie_45settingspFEbIVlXkZMeta?.name ?? "cookie-settings___en",
    path: cookie_45settingspFEbIVlXkZMeta?.path ?? "/en/cookie-settings",
    meta: cookie_45settingspFEbIVlXkZMeta || {},
    alias: cookie_45settingspFEbIVlXkZMeta?.alias || [],
    redirect: cookie_45settingspFEbIVlXkZMeta?.redirect,
    component: () => import("/app/pages/cookie-settings.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___pl",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/en",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93h6ecm9GkHAMeta?.name ?? "news-slug___pl",
    path: _91slug_93h6ecm9GkHAMeta?.path ?? "/aktualnosci/:slug()",
    meta: _91slug_93h6ecm9GkHAMeta || {},
    alias: _91slug_93h6ecm9GkHAMeta?.alias || [],
    redirect: _91slug_93h6ecm9GkHAMeta?.redirect,
    component: () => import("/app/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93h6ecm9GkHAMeta?.name ?? "news-slug___en",
    path: _91slug_93h6ecm9GkHAMeta?.path ?? "/en/news/:slug()",
    meta: _91slug_93h6ecm9GkHAMeta || {},
    alias: _91slug_93h6ecm9GkHAMeta?.alias || [],
    redirect: _91slug_93h6ecm9GkHAMeta?.redirect,
    component: () => import("/app/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: newslJeNrHnHA0Meta?.name ?? "preview-news___pl",
    path: newslJeNrHnHA0Meta?.path ?? "/podglad/aktualnosci",
    meta: newslJeNrHnHA0Meta || {},
    alias: newslJeNrHnHA0Meta?.alias || [],
    redirect: newslJeNrHnHA0Meta?.redirect,
    component: () => import("/app/pages/preview/news.vue").then(m => m.default || m)
  },
  {
    name: newslJeNrHnHA0Meta?.name ?? "preview-news___en",
    path: newslJeNrHnHA0Meta?.path ?? "/en/preview/news",
    meta: newslJeNrHnHA0Meta || {},
    alias: newslJeNrHnHA0Meta?.alias || [],
    redirect: newslJeNrHnHA0Meta?.redirect,
    component: () => import("/app/pages/preview/news.vue").then(m => m.default || m)
  },
  {
    name: simple_45page1iE2CAJyY0Meta?.name ?? "preview-simple-page___pl",
    path: simple_45page1iE2CAJyY0Meta?.path ?? "/podglad/podstrona",
    meta: simple_45page1iE2CAJyY0Meta || {},
    alias: simple_45page1iE2CAJyY0Meta?.alias || [],
    redirect: simple_45page1iE2CAJyY0Meta?.redirect,
    component: () => import("/app/pages/preview/simple-page.vue").then(m => m.default || m)
  },
  {
    name: simple_45page1iE2CAJyY0Meta?.name ?? "preview-simple-page___en",
    path: simple_45page1iE2CAJyY0Meta?.path ?? "/en/preview/simple-page",
    meta: simple_45page1iE2CAJyY0Meta || {},
    alias: simple_45page1iE2CAJyY0Meta?.alias || [],
    redirect: simple_45page1iE2CAJyY0Meta?.redirect,
    component: () => import("/app/pages/preview/simple-page.vue").then(m => m.default || m)
  },
  {
    name: component_45stubTOq3NWgWLHMeta?.name ?? undefined,
    path: component_45stubTOq3NWgWLHMeta?.path ?? "/sitemap.xml",
    meta: component_45stubTOq3NWgWLHMeta || {},
    alias: component_45stubTOq3NWgWLHMeta?.alias || [],
    redirect: component_45stubTOq3NWgWLHMeta?.redirect,
    component: component_45stubTOq3NWgWLH
  }
]