<template>
  <ClientOnly>
    <a
      :href="switchLocalePath(locale === 'pl' ? 'en' : 'pl')"
      class="language-switcher"
    >
      <span :class="{ 'active-locale': locale === 'en' }">en</span>
      <span class="divider">{{ '  /  ' }}</span>
      <span :class="{ 'active-locale': locale === 'pl' }">pl</span>
    </a>
    <template #fallback>
      <span class="language-switcher">
        <span>en</span>
        <span class="divider">{{ '  /  ' }}</span>
        <span>pl</span>
      </span>
    </template>
  </ClientOnly>
</template>

<script setup lang="ts">
const { locale } = useI18n()
const switchLocalePath = useSwitchLocalePath()
</script>

<style lang="scss" scoped>
.language-switcher {
  @include font-size(
    (
      xs: 15px,
      xl: 19px
    )
  );
  @include focus;
  @include center-content(vertically);
  color: $beige-gray;

  .dark-mode & {
    color: $faded-brown;
  }
}

.active-locale {
  color: $dark-brown;

  .dark-mode & {
    color: $beige;
  }
}

.divider {
  white-space: pre;
}
</style>
